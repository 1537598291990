/**
 * @file
 *
 * Customization script for the 'pau/member' form.
 */

import $ from 'jquery'

$(document).ready(() => {
  const $forename = $('[name=etunimet]')
  const $nickname = $('[name=kutsumanimi]')

  $forename.on('blur', () => {
    if ($forename.val()) {
      $nickname.val($forename.val().split(' ')[0])
    }
  })
})
